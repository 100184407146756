.container {
  width: 1164px;
  margin-inline: auto;
  min-height: 80vh;
}

.backGround {
  margin-top: 50px;
  top: 50vh;
  right: 0;
  left: 50%;
  //transform: translateX(-50%);
}
