.depositCard {
  width: 330px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--grey, #343643);
  position: relative;
  padding: 7px 15px 7px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.depositTitle {
  width: 100px;
  color: var(--sea, #76CCCC);

  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bonus {
  color: var(--white, #FFF);

  /* H2 */
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 15px;
}

.free {
  color: var(--sea, #76CCCC);

  /* menu name chosen */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
