.header-image {
  width: 100vw;
}
.overlay-image {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(37, 39, 48, 0.50) 0%, rgba(37, 39, 48, 0.00) 100%);
    z-index: 10;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    z-index: 2;
  }
}
.head-wrapper {
  top: 30%;
  left: 16%;
  width: 500px;
  z-index: 11;
  color: white;
  position: absolute;
}
.head-phrase {
  top: 50%;
  width: 500px;
  z-index: 11;
  color: white;
}
.subtitle-phrase {
  top: 67%;
  width: 500px;
  z-index: 11;
  color: white;
}
.header-button {
  border-radius: 68px;
  background: linear-gradient(90deg, #D83E59 0%, #C32C66 100%), #D83E59;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 270px;
  height: 46px;
  top: 300px;
  z-index: 11;
}

.comingSoon {
  width: 180px;
  height: 75px;
  border-radius: 8px;
  background: var(--sea, #76CCCC);
  position: absolute;
  top: 80px;
  left: 16%;
  z-index: 999;
}
