.container {
  margin-top: 70px;
  max-width: 1164px;
  margin-inline: auto;
  position: relative;
  scroll-behavior: smooth; /* Enable smooth scrolling behavior */
}

.card-container {
  /* Remove display: flex; and flex-wrap properties */
  white-space: nowrap; /* Prevent cards from wrapping to the next line */
  overflow-x: hidden;
  gap: 20px;

  /* Transition property to add smooth animation to the card movement */
  transition: transform 1s ease;
}

.right-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(150%, 60%);
  cursor: pointer;
}

