.spot-card {
  width: 375px;
  height: 210px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  transition: all 1s;
  overflow: hidden;
}

.spot-card-image {
  width: 375px;
  height: 210px;
  object-fit: cover;
  border-radius: 8px;
}

.spot-card-badge {
  background: #76CCCC;
  position: absolute;
  left: 15px;
  border-radius: 30px;
  padding: 3px 12px;
  top: 15px;
  color: var(--white, #FFF);
  text-shadow: 0px 4px 20px 0px rgba(29, 53, 87, 0.50);
  /* spot names */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
