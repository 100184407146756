.container {
  width: 1034px;
}

.promo {
  width: 320px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--sea, #76CCCC);
}

.copyButton {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.promocode {
  position: absolute;
  top: 20px;
  left: 20px;
  color: var(--dark, #252730);
  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.menu-item {
  height: 30px;
  min-width: 150px;
  flex-direction: column;
  justify-content: flex-start;
}

.activeMenuItem {
  color: var(--white, var(--white, #FFF));
  border-bottom: 3px solid #D53B5C;
}

.inactiveMenuItem {
  color: var(--grey-medium, #525460);
  /* spot names */
}

.discount-container {
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  width: 320px;
  height: 90px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  background-position-x: 100%;
  background-size: contain;
  padding: 12px 20px;
  box-sizing: border-box;
}

.amountInput {
  display: flex;
  width: 320px;
  height: 50px;
  padding: 13px 12px;
  align-items: center;
  gap: 38px;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #FFFFFF;
}

.menuRow {
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 10px;
}

@media screen and (max-width: 500px) {
  .container {
    width: 98vw;
  }
}
