.frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.frame .text-wrapper {
  color: var(--white);
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
  margin-top: -1px;
  position: relative;
  width: 90%;
}

.frame .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.frame .text-wrapper-2 {
  color: var(--white);
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: var(--price-letter-spacing);
  margin-top: -1px;
  position: relative;
  width: 90%;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.frame .text-wrapper-3 {
  color: var(--white);
  font-family: var(--spot-names-font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 90%;
}

.frame .p {
  color: var(--white);
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
  position: relative;
  width: 90%;
}

.frame .div-3 {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 90%;
}

.frame .span {
  font-family: var(--menu-name-chosen-font-family);
  font-size: var(--menu-name-chosen-font-size);
  font-style: var(--menu-name-chosen-font-style);
  font-weight: var(--menu-name-chosen-font-weight);
  letter-spacing: var(--menu-name-chosen-letter-spacing);
  line-height: var(--menu-name-chosen-line-height);
}

.frame .text-wrapper-4 {
  color: #ffffff;
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
}

.frame .text-wrapper-5 {
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
  text-decoration: underline;
}

.frame .text-wrapper-6 {
  color: #76CCCC;
  font-family: var(--menu-name-chosen-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  width: 90%;
}

.frame .text-wrapper-7 {
  color:  #76CCCC;
  font-family: var(--menu-name-chosen-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 90%;
}

@media screen and (max-width: 500px){
  .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    padding: 20px;
    padding-bottom: 100px;
  }

  .frame .text-wrapper {
    color: var(--white);
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
    margin-top: -1px;
    position: relative;
    width: 90%;
  }

  .frame .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }

  .frame .text-wrapper-2 {
    color: var(--white);
    font-family: Roboto, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: var(--price-letter-spacing);
    margin-top: -1px;
    position: relative;
    width: 90%;
  }

  .frame .div-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }

  .frame .text-wrapper-3 {
    color: var(--white);
    font-family: var(--spot-names-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 90%;
  }

  .frame .p {
    color: var(--white);
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
    position: relative;
    width: 90%;
  }

  .frame .div-3 {
    color: var(--white);
    font-family: "Roboto", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 90%;
  }

  .frame .span {
    font-family: var(--menu-name-chosen-font-family);
    font-size: var(--menu-name-chosen-font-size);
    font-style: var(--menu-name-chosen-font-style);
    font-weight: var(--menu-name-chosen-font-weight);
    letter-spacing: var(--menu-name-chosen-letter-spacing);
    line-height: var(--menu-name-chosen-line-height);
  }

  .frame .text-wrapper-4 {
    color: #ffffff;
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
  }

  .frame .text-wrapper-5 {
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
    text-decoration: underline;
  }

  .frame .text-wrapper-6 {
    color: #76CCCC;
    font-family: var(--menu-name-chosen-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    width: 90%;
  }

  .frame .text-wrapper-7 {
    color:  #76CCCC;
    font-family: var(--menu-name-chosen-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 90%;
  }

}
