.container {
  float: left;
  left:0;
  top: 60px;
  min-height: 322px;
  background: #fff;
  box-sizing: border-box;
}

.input-wrapper {
  border-radius: 8px;
  width: 200px;
  border: 2px solid var(--grey-medium, #525460);
  background: var(--sea, #76CCCC);
  display: flex;
  height: 72px;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  box-sizing: border-box;
}

.time {
  text-align: center;
  font-family: Roboto;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 115.556% */
  height: 52px;
  width: 52px;
  background: transparent;
  border: none;
}

.selected-wrapper {
  display: flex;
  padding: 8px 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--light-grey, #D3D3D3);
  background: var(--sea, #76CCCC);
  color: white;
}

.not-selected-wrapper {
  display: flex;
  padding: 8px 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  border-bottom: 1px solid var(--light-grey, #D3D3D3);
  color: black;
}

.ampm-wrapper {
  padding: 0;
  border-radius: 8px;
  border: 1px solid var(--light-grey, #D3D3D3);
  background: var(--sea, #76CCCC);
  display: flex;
  width: 52px;
  height: 72px;
  flex-direction: column;
  align-items: flex-start;
}
