.review-address {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.galleryImage {
  width: 572px;
  height: 382px;
  object-fit: contain;
  z-index: 11;
}
