.container {
  padding: 25px 10px 0 10px;
  width: 92%;
  margin-inline: auto;
  height: 180px;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  margin-top: 20px;
}
