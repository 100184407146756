.loginContainer {

  background: transparent;
  box-shadow: 0px 4px 20px rgba(37, 39, 48, 0.20);
  box-sizing: border-box;
  z-index: 999;
}
.registerContainer {
  box-shadow: 0px 4px 20px rgba(37, 39, 48, 0.20);
  box-sizing: border-box;
  z-index: 999;
}

.inputField {
  background: var(--white, #ffffff);
  border-radius: 10px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  width: 320px;
  height: 50px;
  max-width: 100%;
  position: relative;
  padding: 17px 8px 14px 12px;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
}

.inputField.invalid {
  border-color: rgba(255, 0, 0, 0.84);
}

.loginText {
  color: var(--white, #ffffff);
  text-align: left;
  font: var(--price, 700 24px "Roboto", sans-serif);
  position: relative;
  width: 100%;
}

.signUpLink {
  color: var(--white, #ffffff);
  text-align: center;
  font: var(--button, 500 16px "Roboto", sans-serif);
  position: relative;
  width: 300px;
}

.iForgotPassword {
  color: var(--link, #418be2);
  text-align: right;
  font: var(--maintext, 400 16px "Roboto", sans-serif);
}

.regular {
  font: var(--maintext, 400 16px "Roboto", sans-serif);
}

