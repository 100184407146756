.loader {
  position: fixed;
  background: #000000bf;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
