.header-image {
  height: 500px;
  width: 100vw;
  object-fit: cover;
}
.header-image {
  width: 100vw;
}
.overlay-image {
  position: relative;
  min-height: 500px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(37, 39, 48, 0.50);
    z-index: 10;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    z-index: 2;
  }
}
.head-wrapper {
  top: 60%;
  left: 0%;
  width: 100vw;
  transform: translateY(-60%);
  z-index: 11;
  color: white;
  padding: 0;
  position: absolute;
}
.head-phrase {
  width: 100%;
  z-index: 11;
  color: white;
  font-weight: 700;
}
.subtitle-phrase {
  top: 67%;
  width: 100vw;
  z-index: 11;
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}
.header-button {
  border-radius: 68px;
  background: linear-gradient(90deg, #D83E59 0%, #C32C66 100%), #D83E59;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 270px;
  height: 46px;
  top: 300px;
  z-index: 11;
  margin-inline: auto;
}

.aboutImage {
  width: 100%;
  height: 220px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #D9D9D9;
  margin-top: 20px;
}


.card {
  display: flex;
  height: 340px;
  width: 215px;
  padding: 35px 20px 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--grey, #343643);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.cardImg {
  height: 130px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.comingSoon {
  width: 100vw;
  height: 40px;
  position: absolute;
  background: #76CCCC;
  z-index: 999;
  text-decoration: none;
}
