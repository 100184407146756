.hiw-block-items {
  background: #252730;
}

.page {
  min-height: 2300px !important;
  margin-top: -40px;
}

.second {
  position: relative;
  top: 27%;
  right: 0
}

.third {
  position: relative;
  top: 45%;
  left: 0;
}

.path {
  z-index: 1;
  position: absolute;
  top: 190px;
  left: 3px;
}

.hiw-card {
  width: 98vw;
  z-index: 2;
}

.sea-dot {
  min-width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #76CCCC;
}

.content {
  background: #252730;
}
