.footer {
  width: 100vw;
  background: var(--grey, #343643);
  box-sizing: border-box;
  padding: 24px 20px 13px 20px;
  position: absolute;
}

.telegram {
  width: 130px;
  height: 30px;
  border-radius: 30px;
  background: #039BE5;
  cursor: pointer;
}

.whatsapp {
  width: 130px;
  height: 30px;
  border-radius: 30px;
  background: #00A650;
  cursor: pointer;
}
