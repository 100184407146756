body {

}

.container {
  margin-top: 50px;
}



.mapWrapper {
  z-index: 1000;
  height: 590px;
  width: 100%;
  left: 2%;
  top: 55%;
  padding-right: 50px;
  box-sizing: border-box;
}

.map {

}

.closeIcon {
  position: absolute;
  top: 10%;
  right: 30px;
  cursor: pointer;
}

.gm-style-iw .gm-style-iw-c {
  background: transparent; /* Set the background to transparent */
  border: none; /* Remove any borders */
  box-shadow: none; /* Remove any box shadows */
  padding: 0; /* Adjust padding as needed */
}


.miniCard {
  width: 90px;
  height: 135px;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #888888;
  box-sizing: border-box;
  background:  #fff;
}

.selected {
  background: #888888 ;
}

.miniCard img {
  width: 80px;
  height: 80px;
  object-fit: fill;
  border-radius: 4px;
  margin-inline: auto;
}
@media screen and (max-width: 500px) {
  .mapWrapper {
    z-index: 1000;
    height: 260px;
    width: 100%;
    left: 2%;
    top: 55%;
    padding-right: 50px;
    box-sizing: border-box;
  }
}
