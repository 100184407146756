.scrollable-container {
  overflow-y: auto; /* or 'scroll' */
  max-height: 60%; /* Set the desired max height for the container */
  overflow-x: hidden;
  margin-bottom: 30px;
}

.scrollable-container::-webkit-scrollbar {
  width: 12px; /* Adjust the width of the scrollbar */
}

/* Track - the background of the scrollbar */
.scrollable-container::-webkit-scrollbar-track {
  background: #343643; /* Dark background color */
}

/* Handle - the draggable part of the scrollbar */
.scrollable-container::-webkit-scrollbar-thumb {
  background: #76CCCC; /* Dark color for the handle */
  border-radius: 8px; /* Round the corners of the handle */
}

.scroll-fade {
  /* Add smooth opacity transition here */
  /* For example: */
  opacity: 1;
  transition: opacity 0.5s ease;
}

.select-book-time-window {
  width: 500px;
  height: 679px;
  background: #343643;
  box-shadow: -1px -5px 300px 30px rgb(32, 34, 37);
  border-radius: 8px;
  padding: 20px 15px;
  box-sizing: border-box;
  position: relative;
}

.filter {
  height: 60px;
  padding: 10px;
}

.leftFilter {
  border-right: 1px solid var(--grey-medium, #525460);
}

.border {
  border: 1px solid var(--grey-medium, #525460);
}

.select-time-select {
  height: 50px;
  border: 1px solid var(--grey-medium, #525460);
  border-radius: 10px;
  margin-bottom: 20px;
}

.v-divider {
  height: 100%;
  width: 1px;
  background: #525460;
}

.price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #FFFFFF;
}

.old-price {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: line-through;
  color: #FFFFFF;
}

.rating {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #76CCCC;
}

.reviews-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #FFFFFF;
}

.hours-table {
  overflow-y: auto;
}

.border {
  border: 1px solid #525460;
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 72px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}

.border-body {
  border: 1px solid #525460;
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 72px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
}

.free:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  color: #76CCCC;
  transition: all 1s;
  font-weight: bold;
}

.selected {
  background: #76CCCC;
}

.hours-select {
  position: absolute;
  z-index: 11;
  top: 60px;
  right: 0px;
  width: 215px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--dark, #343643);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
}

.list-item {
  box-sizing: border-box;
  padding: 10px;
}

.list-item:hover {
  background: #525460;
}

.time-selection-container {
  position: absolute;
  width: 390px;
  height: 474px;
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.2);
  border-radius: 30px;
  box-sizing: border-box;
  padding: 26px 20px;
  z-index: 99;
}
.time-selection-container-footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
  border-top: 1px solid #D3D3D3;
}
.text-buttons-container {
  padding: 18px 24px 22px 0;
}
.text-buttons {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;

  /* magenta gradient */

  background: linear-gradient(90deg, #D83E59 0%, #C32C66 99.78%), #D83E59;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;
}
.time-selection-header-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  text-align: center;

  /* dark */

  color: #252730;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 110px);
  grid-template-rows: repeat(8, 30px);
  gap: 10px;
  margin-top: 30px;
}

.grid-item {
  border: 1px solid #D3D3D3;
  width: 110px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;

  color: #000000;
  cursor: pointer;
}
.grid-item:hover {
  background: #76CCCC;
  border-radius: 4px;
  color: #FFFFFF;
}
.selected {
  background: #76CCCC;
  border-radius: 4px;
  color: #FFFFFF;
}
@media screen and (max-width: 767px){
  .time-selection-container {
    position: absolute;
    width: 300px;
    height: 400px;
    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.2);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 26px 20px;
    overflow-y: scroll;
  }
  .time-selection-container-footer {
    position: absolute;
    bottom: 0;
    height: 60px;
    left: 0;
    right: 0;
    border-top: 1px solid #D3D3D3;
  }
  .text-buttons-container {
    padding: 18px 24px 22px 0;
  }
  .text-buttons {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;

    /* magenta gradient */

    background: linear-gradient(90deg, #D83E59 0%, #C32C66 99.78%), #D83E59;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    cursor: pointer;
  }
  .time-selection-header-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height, or 127% */

    text-align: center;

    /* dark */

    color: #252730;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 110px);
    grid-template-rows: repeat(8, 30px);
    gap: 10px;
    max-height: 250px;
    overflow-y: auto;
    margin-top: 30px;
  }

  .grid-item {
    border: 1px solid #D3D3D3;
    width: 110px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.02em;

    color: #000000;
    cursor: pointer;
  }
  .grid-item:hover {
    background: #76CCCC;
    border-radius: 4px;
    color: #FFFFFF;
  }
  .selected {
    background: #76CCCC;
    border-radius: 4px;
    color: #FFFFFF;
  }
}

.popup {
  border-radius: 10px;
  background: var(--white, #FFF);
  display: inline-flex;
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
  height: 60px;
  top: 30px;
  right: 20px;
  position: absolute;
}
