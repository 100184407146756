.breadcrumbs-text {
  color: var(--grey-medium, #525460);
  /* time + bread text */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
