@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

// Grid System
:root {
  --grid-columns: 24;
  --gutter-width: 10px;
}

/* For modern browsers */
::placeholder {
  color: #D3D3D3; /* Replace with your desired color */
}

/* For older versions of some browsers (e.g., Chrome 49-), use the following selector */
::-webkit-input-placeholder {
  color: #D3D3D3; /* Replace with your desired color */
}

/* For Firefox 18- */
:-moz-placeholder {
  color: #D3D3D3; /* Replace with your desired color */
}

/* For Firefox 19+ */
::-moz-placeholder {
  color: #D3D3D3; /* Replace with your desired color */
}

/* For Edge 18- */
:-ms-input-placeholder {
  color: #D3D3D3; /* Replace with your desired color */
}


body {
  background: var(--dark, #252730);
  color: white;
  overflow-x: hidden;
}

body.popup-open {
  overflow: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.row {
  display: flex;
  flex-direction: row;
  //margin: calc(var(--gutter-width) / 2 * -1);
}

.col {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: calc(var(--gutter-width) / 2);
}

.relative {
  position: relative;
}

@for $i from 1 through 100 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}

@for $i from 1 through 25 {
  .br-#{$i} {
    border-radius: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .text-#{$i} {
    font-family: Roboto, sans-serif;
    font-size: #{$i}px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@for $i from 1 through 100 {
  .line-height-#{$i} {
    line-height: #{$i}px;
  }
}

@for $i from 1 through 24 {
  .col-#{$i} {
    width: calc((100% / var(--grid-columns)) * #{$i});
  }
}

// Margins
@for $i from 0 through 999 {
  .ma-#{$i} {
    margin: #{$i}px !important;
  }
  .mx-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
  .my-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
}

// Paddings
@for $i from 0 through 100 {
  .pa-#{$i} {
    padding: #{$i}px !important;
  }
  .px-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .py-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
}

// Flexbox Utilities
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-height {
  height: 97vh;
}


// Common styles

.red-button {
  border-radius: 68px;
  background: linear-gradient(90deg, #D83E59 0%, #C32C66 100%), #D83E59;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 270px;
  height: 46px;
  cursor: pointer;
  flex-wrap: wrap;
}
.sea-button {
  border-radius: 68px;
  background: linear-gradient(90deg, #76CCCC 0%, #7ae3e3 100%), #288d8d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 270px;
  height: 46px;
  cursor: pointer;
  flex-wrap: wrap;
}
.red-button:hover {
  border-radius: 68px;
  background: #9F2951;
  width: 270px;
  height: 46px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.red-button-text {
  color: var(--white, #FFF);
  text-align: center;
  /* button */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pointer {
  cursor: pointer;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.7);
}

.main-text {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h2-text {
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blue-text {
  color: var(--link, #418be2);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.magenta-text {
  color: #D83E59;
}

.text-button {
  color: var(--magenta-gradient, #D83E59);
  text-align: center;
  /* link button */
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px;
}

.text-price {
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.black-button {
  width: 270px;
  height: 46px;
  background: #252730;
  border-radius: 30px;
  border: 0.50px #D83E59 solid;
  cursor: pointer;
}

.black-button span {
  text-align: center;
  color: #D53B5C;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.text-align-center {
  text-align: center;
}

.wrapper {
  width: 1164px;
  margin-inline: auto;
  padding-top: 30px;
  min-height: 85vh;
}

.h3-text {
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.sea-text {
  color: var(--sea, #76CCCC);
}

.spot-subtitle {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.wrap {
  flex-wrap: wrap;
}

.spot-title {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.menu-name-chosen{
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-input-wrapper {
  border-radius: 10px;
  background: var(--white, #FFF);
  width: 300px;
  height: 50px;
  padding: 6px 12px;
}

.booking-input-wrapper input {
  border: none;
  font-size: 20px;
}

.booking-input-wrapper input:focus {
  outline: none;
  border-radius: 5px;
}

.booking-input-wrapper:focus-within {
  border: 2px solid #D53B5C;
  outline: none;
  border-radius: 10px;
  box-sizing: content-box;
}

.booking-input-wrapper span {
  color: var(--light-grey, #D3D3D3);
  /* field name */
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.review-address {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.sea-badge {
  height: 30px;
  background: #76CCCC;
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: 17px;
}

.underline {
  text-decoration: underline;
}
.crossline {
  text-decoration: line-through;
}

.no-scroll::-webkit-scrollbar {
  width: 6px; /* Set a width for the scrollbar (adjust as needed) */
}

.no-scroll::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}

.no-scroll::-webkit-scrollbar-track {
  background-color: transparent; /* Make the track transparent */
}

.border-box {
  box-sizing: border-box;
}

.light-gray {
  color: var(--light-grey, #D3D3D3);
}

.button-dark {
  border-radius: 68px;
  border: 1px solid var(--red-berry, #D53B5C);
  background: var(--dark, #252730);
  width: 180px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.keycode-wrapper {
  border-radius: 10px;
  border: 2px solid var(--red-berry, #D53B5C);
  background: var(--white, #FFF);
  width: 320px;
  height: 57px;
  box-sizing: border-box;
  padding: 18px 24px;

}

.bold {
  font-weight: 700;
}

.payment-type-inactive {
  color: var(--grey-medium, #525460);

  /* price */
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  min-width: 125px;
  height: 43px;
  border-bottom: 1px solid #525460;
}

.payment-type-active {
  color: var(--white, var(--white, #FFF));

  /* price */
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  min-width: 125px;
  height: 42px;
  border-bottom: 3px solid #D53B5C;
}

.inactive {
  opacity: 0.5;
}

.dark-input {
  box-sizing: border-box;
  padding: 12px;
  display: flex;
  width: 320px;
  height: 50px;
  padding: 13px 12px;
  align-items: center;
  gap: 38px;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  color: var(--white, var(--white, #FFF));

  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cert {
  width: 572px;
  height: 239px;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #343643;
  flex-direction: column;
  display: flex;
  padding: 41px 32px;
  position: relative;
  box-sizing: border-box;
}

.music-key {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.uppercase {
  text-transform: uppercase;
}

.folded-cert {
  width: 572px;
  height: 239px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--sea, #76CCCC);
  position: relative;
  box-sizing: border-box;
  padding: 40px 15px 40px 25px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.cert-field {
  border-radius: 10px;
  border: 1px solid var(--white, #FFF);
  background: var(--white, #FFF);
  width: 320px;
  height: 60px;
  flex-shrink: 0;
}

.verification-input {
  border-radius: 10px;
  border: 2px solid var(--red-berry, #D53B5C);
  background: var(--white, #FFF);
  width: 149.86px;
  height: 57px;
  box-sizing: border-box;
  padding: 20px;
  flex-shrink: 0;
  color: var(--dark, #252730);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deposit-container {
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  width: 42%;
  height: 239px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  overflow: hidden;
  background-position-x: -50%;
  background-size: contain;
  padding: 12px 20px;
}

.deposit-container-even-title {

  /* H3 Spot names */
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.deposit-container-odd-title {

  /* H3 Spot names */
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.deposit-container-button {
  width: 200px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 30px;
}

.amountInput {
  display: flex;
  width: 320px;
  height: 50px;
  padding: 13px 12px;
  align-items: center;
  gap: 38px;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #FFFFFF;
}

@media screen and (max-width: 500px) {
  .wrapper {
    width: 100vw;
    box-sizing: border-box;
    padding-bottom: 100px;
  }

  .text-price {
    font-family: Roboto, sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .booking-input-wrapper {
    width: 85vw;
  }
  .cert {
    width: 92vw;
    height: 239px;
    flex-shrink: 0;
    border-radius: 10px;
    background-color: #343643;
    flex-direction: column;
    display: flex;
    padding: 41px 32px;
    position: relative;
    box-sizing: border-box;
  }
  .folded-cert {
    width: 92vw;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--sea, #76CCCC);
    position: relative;
    box-sizing: border-box;
    padding: 20px 10px 20px 10px;
    flex-direction: column;
    display: flex;
    overflow: hidden;
    height: auto;
  }
  .cert-field {
    border-radius: 10px;
    border: 1px solid var(--white, #FFF);
    background: var(--white, #FFF);
    width: 96%;
    height: 60px;
    flex-shrink: 0;
  }
  .deposit-container {
    border-radius: 10px;
    border: 1px solid var(--grey-medium, #525460);
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    overflow: hidden;
    background-size: contain;
    padding: 0;
    padding-top: 30px;
    margin-inline: auto;
  }

  .deposit-container-even-title {

    /* H3 Spot names */
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .deposit-container-odd-title {

    /* H3 Spot names */
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .deposit-container-button {
    width: 200px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 30px;
  }
}
