.container {
  display: flex;
  max-width: 1164px;
  height: 60px;
  margin-top: 27px;
  margin-inline: auto;
  padding: 7px 11px 7px 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  box-sizing: border-box;
  position: relative;
  z-index: 11;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  background: rgba(22, 26, 32, 0.50);
}

.unfoldedContainer {
  display: flex;
  max-width: 1164px;
  width: 1164px;
  height: 60px;
  margin-top: -80px;
  margin-inline: auto;
  padding: 7px 11px 7px 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  box-sizing: border-box;
  position: relative;
  z-index: 11;
}

.input-wrapper {
  width: 332px;
  height: 40px;
  position: relative;
}

.divider {
  height: 40px;
  width: 1px;
  background: #D3D3D3;
  margin-right: 20px;
}

.input-button {
  max-width: 200px;
  color: var(--light-grey, #D3D3D3);
}
