.container {
  width: 800px;
}

.message {
  border-radius: 8px;
  background: var(--grey, #343643);
  padding: 20px;
  width: 800px;
  box-sizing: border-box;
}

.messageUnread {
  border-radius: 8px;
  background: var(--grey-medium, #525460);
  padding: 20px;
  width: 800px;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .message {
    border-radius: 8px;
    background: var(--grey, #343643);
    padding: 20px;
    width: 90vw;
    box-sizing: border-box;
  }

  .messageUnread {
    border-radius: 8px;
    background: var(--grey-medium, #525460);
    padding: 20px;
    width: 90vw;
    box-sizing: border-box;
  }
  .container {
    width: 90vw;
  }
}
