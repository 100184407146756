.menu {
  border-radius: 10px;
  background: var(--white, #FFF);
  width: 125px;
  height: 68px;
  position: absolute;
  top: 100%;
  left: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.menuItem:hover {
  background: #dddddd;
}
