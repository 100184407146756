.container {
  width: 100vw;
  margin-inline: auto;
  min-height: 80vh;
  overflow: hidden;
  box-sizing: border-box;
}

.backGround {
  margin-left: -100px;
  margin-top: 50px;
  top: 50vh;
  right: 0;
  left: 50%;
  //transform: translateX(-50%);
}
