.include-item {
  width: 200px;
}

.seaDivider {
  width: 98%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #76CCCC;
  box-sizing: border-box;

}

.promo-wrapper {
  width: 320px;
  height: 50px;
  border-radius: 10px;
  background: var(--sea, #76CCCC);
  padding: 12px 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-wrapper-input {
  background: transparent;
  font-size: 22px;
  border: none;
  text-transform: uppercase;
  height: 48px;
  max-width: 80%;
}
.promo-wrapper-applied {
  width: 320px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  padding: 12px 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-wrapper-input-applied {
  background: transparent;
  font-size: 22px;
  border: none;
  text-transform: uppercase;
  height: 48px;
  color: #76CCCC;
  max-width: 80%;

}
.promo-wrapper-wrong {
  width: 320px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  padding: 12px 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-wrapper-input-wrong {
  background: transparent;
  font-size: 22px;
  border: none;
  text-transform: uppercase;
  height: 48px;
  color: #D53B5C;
  max-width: 80%;
}

@media screen and (max-width: 500px){
  .promo-wrapper {
    width: 300px;
  }
  .promo-wrapper-wrong {
    width: 300px;
  }
  .promo-wrapper-applied {
    width: 300px;
  }
}
