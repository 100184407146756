:root {
  --button-font-family: "Roboto-Medium", Helvetica;
  --button-font-size: 16px;
  --button-font-style: normal;
  --button-font-weight: 500;
  --button-letter-spacing: 0px;
  --button-line-height: normal;
  --dark: rgba(37, 39, 48, 1);
  --edit-font-family: "Roboto-Regular", Helvetica;
  --edit-font-size: 12px;
  --edit-font-style: normal;
  --edit-font-weight: 400;
  --edit-letter-spacing: 0px;
  --edit-line-height: normal;
  --field-name-font-family: "Roboto-Regular", Helvetica;
  --field-name-font-size: 10px;
  --field-name-font-style: normal;
  --field-name-font-weight: 400;
  --field-name-letter-spacing: 0px;
  --field-name-line-height: normal;
  --grey: rgba(52, 54, 67, 1);
  --grey-medium: rgba(82, 84, 96, 1);
  --h1-banner-text-font-family: "Roboto-Bold", Helvetica;
  --h1-banner-text-font-size: 60px;
  --h1-banner-text-font-style: normal;
  --h1-banner-text-font-weight: 700;
  --h1-banner-text-letter-spacing: 0px;
  --h1-banner-text-line-height: 60px;
  --h2-font-family: "Roboto-Bold", Helvetica;
  --h2-font-size: 38px;
  --h2-font-style: normal;
  --h2-font-weight: 700;
  --h2-letter-spacing: 0px;
  --h2-line-height: normal;
  --h3-spot-names-font-family: "Roboto-Bold", Helvetica;
  --h3-spot-names-font-size: 26px;
  --h3-spot-names-font-style: normal;
  --h3-spot-names-font-weight: 700;
  --h3-spot-names-letter-spacing: 0px;
  --h3-spot-names-line-height: normal;
  --light-grey: rgba(211, 211, 211, 1);
  --link: rgba(65, 139, 226, 1);
  --link-button-font-family: "Roboto-Medium", Helvetica;
  --link-button-font-size: 14px;
  --link-button-font-style: normal;
  --link-button-font-weight: 500;
  --link-button-letter-spacing: 0px;
  --link-button-line-height: normal;
  --magenta-gradient: rgba(216, 62, 89, 1);
  --main-text-font-family: "Roboto-Regular", Helvetica;
  --main-text-font-size: 16px;
  --main-text-font-style: normal;
  --main-text-font-weight: 400;
  --main-text-letter-spacing: 0px;
  --main-text-line-height: normal;
  --main-text-med-font-family: "Roboto-Medium", Helvetica;
  --main-text-med-font-size: 16px;
  --main-text-med-font-style: normal;
  --main-text-med-font-weight: 500;
  --main-text-med-letter-spacing: 0px;
  --main-text-med-line-height: normal;
  --menu-name-chosen-font-family: "Roboto-Bold", Helvetica;
  --menu-name-chosen-font-size: 16px;
  --menu-name-chosen-font-style: normal;
  --menu-name-chosen-font-weight: 700;
  --menu-name-chosen-letter-spacing: 0px;
  --menu-name-chosen-line-height: normal;
  --old-price-font-family: "Roboto-Medium", Helvetica;
  --old-price-font-size: 14px;
  --old-price-font-style: normal;
  --old-price-font-weight: 500;
  --old-price-letter-spacing: 0px;
  --old-price-line-height: normal;
  --price-font-family: "Roboto-Bold", Helvetica;
  --price-font-size: 26px;
  --price-font-style: normal;
  --price-font-weight: 700;
  --price-letter-spacing: 0px;
  --price-line-height: normal;
  --red-berry: rgba(213, 59, 92, 1);
  --review-adress-font-family: "Roboto-Medium", Helvetica;
  --review-adress-font-size: 16px;
  --review-adress-font-style: normal;
  --review-adress-font-weight: 500;
  --review-adress-letter-spacing: 0px;
  --review-adress-line-height: normal;
  --sea: rgba(118, 204, 204, 1);
  --spot-names-font-family: "Roboto-Bold", Helvetica;
  --spot-names-font-size: 20px;
  --spot-names-font-style: normal;
  --spot-names-font-weight: 700;
  --spot-names-letter-spacing: 0px;
  --spot-names-line-height: normal;
  --text-14-font-family: "Roboto-Regular", Helvetica;
  --text-14-font-size: 14px;
  --text-14-font-style: normal;
  --text-14-font-weight: 400;
  --text-14-letter-spacing: 0px;
  --text-14-line-height: normal;
  --time-bread-text-font-family: "Roboto-Regular", Helvetica;
  --time-bread-text-font-size: 12px;
  --time-bread-text-font-style: normal;
  --time-bread-text-font-weight: 400;
  --time-bread-text-letter-spacing: 0px;
  --time-bread-text-line-height: normal;
  --white: rgba(255, 255, 255, 1);
}

.frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.frame .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.frame .text-wrapper {
  color: var(--white);
  font-family: var(--price-font-family);
  font-size: var(--price-font-size);
  font-style: var(--price-font-style);
  font-weight: var(--price-font-weight);
  letter-spacing: var(--price-letter-spacing);
  line-height: var(--price-line-height);
  margin-top: -1px;
  position: relative;
  width: 809px;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.frame .p {
  color: var(--white);
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
  margin-top: -1px;
  position: relative;
  width: 809px;
}

.frame .text-wrapper-2 {
  color: var(--white);
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
  position: relative;
  width: 809px;
}

.frame .text-wrapper-3 {
  color: var(--white);
  font-family: var(--spot-names-font-family);
  font-size: var(--spot-names-font-size);
  font-style: var(--spot-names-font-style);
  font-weight: var(--spot-names-font-weight);
  letter-spacing: var(--spot-names-letter-spacing);
  line-height: var(--spot-names-line-height);
  margin-top: -1px;
  position: relative;
  width: 809px;
}

.frame .span {
  color: #ffffff;
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
}

.frame .text-wrapper-4 {
  font-family: var(--main-text-font-family);
  font-size: var(--main-text-font-size);
  font-style: var(--main-text-font-style);
  font-weight: var(--main-text-font-weight);
  letter-spacing: var(--main-text-letter-spacing);
  line-height: var(--main-text-line-height);
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
  }

  .frame .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }

  .frame .text-wrapper {
    color: var(--white);
    font-family: var(--price-font-family);
    font-size: var(--price-font-size);
    font-style: var(--price-font-style);
    font-weight: var(--price-font-weight);
    letter-spacing: var(--price-letter-spacing);
    line-height: var(--price-line-height);
    margin-top: -1px;
    position: relative;
    width: 88vw;
  }

  .frame .div-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }

  .frame .p {
    color: var(--white);
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
    margin-top: -1px;
    position: relative;
    width: 88vw;
  }

  .frame .text-wrapper-2 {
    color: var(--white);
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
    position: relative;
    width: 88vw;
  }

  .frame .text-wrapper-3 {
    color: var(--white);
    font-family: var(--spot-names-font-family);
    font-size: var(--spot-names-font-size);
    font-style: var(--spot-names-font-style);
    font-weight: var(--spot-names-font-weight);
    letter-spacing: var(--spot-names-letter-spacing);
    line-height: var(--spot-names-line-height);
    margin-top: -1px;
    position: relative;
    width: 88vw;
  }

  .frame .span {
    color: #ffffff;
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
  }

  .frame .text-wrapper-4 {
    font-family: var(--main-text-font-family);
    font-size: var(--main-text-font-size);
    font-style: var(--main-text-font-style);
    font-weight: var(--main-text-font-weight);
    letter-spacing: var(--main-text-letter-spacing);
    line-height: var(--main-text-line-height);
    text-decoration: underline;
  }
}
