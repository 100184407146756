.topBar {
  width: 100vw;
  height: 90px;
  background: #252730;
  z-index: 999;
}

.menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #252730;
  z-index: 999;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 100px;
}

.telegram {
  width: 130px;
  height: 30px;
  border-radius: 30px;
  background: #039BE5;
  cursor: pointer;
}
.whatsapp {
  width: 130px;
  height: 30px;
  border-radius: 30px;
  background: #00A650;
  cursor: pointer;
}
