.container {
  background: #282c34;
}
.text {
  color: var(--white, #FFF);
  text-align: center;
  /* H2 */
  font-family: Roboto, serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.plugsWrapper {
  margin-top: -50px;
  z-index: 10;
}
