/* UniversalPopup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;

}

.popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 300px;
  z-index: 10;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  text-align: center;
  color: #282c34;
}

.close-icon {
  font-size: 18px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: -30px;
  right: 0;
}

.popup-content {
  padding: 10px 0;
  text-align: center;
  color: #282c34;
}

.popup-footer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  gap: 30px;
  align-items: center;
}

.popup-footer button {
  margin-left: 10px;
}

.buttonOne {
  background: var(--magenta-gradient, linear-gradient(90deg, #D83E59 0%, #C32C66 99.78%));
  padding: 5px 10px;
  box-sizing: border-box;
  height: 40px;
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
}

.inputField {
  background: var(--white, #ffffff);
  border-radius: 10px;
  border-style: solid;
  border-color: #e2d8d8;
  border-width: 1px;
  width: 320px;
  height: 50px;
  max-width: 100%;
  position: relative;
  padding: 17px 8px 14px 12px;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
}
.textareaField {
  background: var(--white, #ffffff);
  border-radius: 10px;
  border-style: solid;
  border-color: #e2d8d8;
  border-width: 1px;
  width: 320px;
  max-width: 100%;
  position: relative;
  padding: 17px 8px 14px 12px;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
}

.inputField.invalid {
  border-color: rgba(255, 0, 0, 0.84);
}

.loginText {
  color: var(--white, #ffffff);
  text-align: left;
  font: var(--price, 700 24px "Roboto", sans-serif);
  position: relative;
  width: 100%;
}

.signUpLink {
  color: var(--white, #ffffff);
  text-align: center;
  font: var(--button, 500 16px "Roboto", sans-serif);
  position: relative;
  width: 300px;
}

.iForgotPassword {
  color: var(--link, #418be2);
  text-align: right;
  font: var(--maintext, 400 16px "Roboto", sans-serif);
}

.regular {
  font: var(--maintext, 400 16px "Roboto", sans-serif);
}

.caption {
  width: 40%;
}

@media screen and (max-width: 500px) {
  .mobile {
    width: 88vw;
    margin-inline: auto;
  }
  .caption {
    width: 80vw;
  }
}
