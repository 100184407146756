/* UniversalPopup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.popup-container {
  background-color: #fff;
  padding: 44px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 354px;
  box-sizing: border-box;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  text-align: center;
  color: #282c34;
}

.close-icon {
  font-size: 18px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: -30px;
  right: 0;
}

.popup-content {
  padding: 10px 0;
  text-align: center;
  color: #282c34;
}

.popup-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  gap: 30px;
  align-items: center;
}

.popup-footer button {
  margin-left: 10px;
}

.buttonOne {
  background: #9F2951;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 40px;
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
}

.textareaInput {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #D3D3D3);
  background: var(--white, #FFF);
  padding: 8px;
}
