.hiw-block-items {
  width: 400px;
  background: #252730;

}

.page {
  min-height: 2200px !important;
}

.second {
  position: absolute;
  top: 27%;
  right: 0
}

.third {
  position: absolute;
  top: 45%;
  left: 0;
}

.path {
  z-index: 1;
  position: absolute;
  top: 130px;
  left: 50px;
}

.hiw-card {
  width: 530px;
  z-index: 2;
}

.sea-dot {
  min-width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #76CCCC;
}

.content {
  background: #252730;
}
