.draft-image {
  width: 572px;
  object-fit: contain;
  z-index: 11;
}
.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 12;
}

.popup {
  border-radius: 10px;
  background: var(--white, #FFF);
  display: inline-flex;
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
  height: 60px;
  top: 20px;
  right: 45px;
  position: absolute;
}
