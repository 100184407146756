.edit-text {
  /* edit */
  color: var(--grey-medium, #525460) !important;
  text-align: right;
  font-family: Roboto, sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.discount-container {
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  width: 320px;
  height: 90px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  background-position-x: 100%;
  background-size: contain;
  padding: 12px 20px;
}

.copyButton {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.promocode {
  position: absolute;
  top: 20px;
  left: 20px;
  color: var(--dark, #252730);
  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.promo {
  width: 320px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--sea, #76CCCC);
}

.activateCertificate {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #D3D3D3);
  background: var(--white, #FFF);
  width: 320px;
  height: 60px;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 18px;
}

.activateCertificate input {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--dark, #252730);
}
.activateCertificate input:focus {
  input:focus {outline:none!important;}

}

.amountInput {
  border-radius: 10px;
  height: 60px;
  border:0;outline:0;

  box-sizing: border-box;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.applyBtn {
  color: var(--sea, #76CCCC);
  /* button */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
