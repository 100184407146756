.menu-container {
  border-radius: 8px;
  background: var(--grey, #343643);
  width: 310px;
  box-sizing: border-box;
  height: 450px;
  padding: 20px;
  margin-top: 50px;
  margin-left: 20px;

}

.selected-item {
  color: var(--red-berry, #D53B5C);
  /* menu name chosen */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.divider {
  width: 270px;
  height: 1px;
  background: #525460;
}

.logout {
  color: var(--link, #418BE2);
  /* main text */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 500px){
  .menu-container {
    border-radius: 8px;
    background: var(--grey, #343643);
    width: 90vw;
    box-sizing: border-box;
    height: 450px;
    padding: 20px;
    margin-top: 50px;
    margin-left: 20px;

  }
}
