.divider {
  width: 800px;
  height: 20px;
  box-sizing: border-box;
  background: #676A7C;
  padding: 2px 20px;

}
.divider span {
  text-align: center;
}
