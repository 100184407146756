.container {
  width: 1034px;
}

.promo {
  width: 320px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--sea, #76CCCC);
}

.copyButton {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.promocode {
  position: absolute;
  top: 20px;
  left: 20px;
  color: var(--dark, #252730);
  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.menu-item {
  height: 30px;
  flex-direction: column;
  justify-content: flex-start;
}

.activeMenuItem {
  color: var(--white, var(--white, #FFF));
  border-bottom: 3px solid #D53B5C;
}

.inactiveMenuItem {
  color: var(--grey-medium, #525460);
  /* spot names */
}

.discount-container {
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  width: 320px;
  height: 90px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  background-position-x: 100%;
  background-size: contain;
  padding: 12px 20px;
  box-sizing: border-box;
}

.amountInput {
  display: flex;
  width: 320px;
  height: 50px;
  padding: 13px 12px;
  align-items: center;
  gap: 38px;
  border-radius: 10px;
  border: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #FFFFFF;
}
.promo-sea {
  /* menu name chosen */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

th {
  width: 180px;
}

td {
  font-size: 14px;
  text-align: center;
  padding: 10px 0 10px 0;
}

tr {
  border-bottom: 1px solid rgba(82, 84, 96, 1);
}
/* Optional: Remove bottom border for last row */
tbody tr:last-child {
  border-bottom: none;
}

.thead {
  background-color: rgba(118, 204, 204, 0.2);
  height: 48px;
;
  color: #FFFFFF;
}

.inp {
  /* Frame 13 */

  box-sizing: border-box;
background: rgba(52, 54, 67, 1);
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 20px;
  gap: 10px;
  border-radius: 30px;
  border: none;
  width: 300px;
  height: 40px;


  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* white */
  color: #FFFFFF;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;


  /* light grey */

}

@media screen and (max-width: 500px) {
  .container {
    width: 98vw;
  }
}
