.editForm {

}

@media screen and (max-width: 500px) {
  .editForm {
    position: absolute;
    top: 100px;
    left: 0;
    max-width: 100vw !important;
    overflow: hidden;
  }
}
