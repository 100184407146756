.card {
  border-radius: 8px;
  background: var(--white, #FFF);
  padding: 15px 15px 20px 15px;
  width: 330px;
  height: 566px;
  box-sizing: border-box;
}

.inCardImage {
  border-radius: 8px;
  position: relative;
}

.inCardImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust the opacity (last value) to control darkness */
}


.leftChevron {
  transform: rotate(180deg);
  left: 0;
}


.buttonRight {
  position: absolute;
  top: 50%;
  right: 10px;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  // transform: translate(50%, -50%);
  z-index: 999;
  cursor: pointer;
}

.buttonLeft {
  position: absolute;
  top: 50%;
  left: 10px;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  z-index: 999;
  cursor: pointer;
}

.dots {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: white;
}

.inactiveDot {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: #525460;
}

.spotsName {
  color: var(--dark, #252730);
  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
