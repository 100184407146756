.container {
  margin-top: 70px;
  max-width: 1164px;
  margin-inline: auto;
  justify-content: space-between;
}

.card {
  display: flex;
  height: 340px;
  width: 215px;
  padding: 35px 20px 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--grey, #343643);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.cardImg {
  height: 130px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
