.container {
  background: #252730;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.60);
  width: 100vw;
  height: 80px;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 999;
}

.nav-item {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  list-style-type: none;
  text-decoration: none;
}

.nav-item a {
  text-decoration: none;
  color: white;
}

.avatar {
  min-width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #76cccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.balance {
  color: var(--sea, #76CCCC);

  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
