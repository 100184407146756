.container {
  width: 220px;
  background: white;
  box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.20);
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  left: 672px;
  top: 70px;
  padding: 19px;
  z-index: 10;

}
