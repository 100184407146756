.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 240px;
  background: #343643;
  box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.20);
  z-index: 111;
}

.inner-wrapper {
  width: 1140px;
  margin-inline: auto;
}

@media  screen and (max-width: 500px) {
  .container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 400px;
    background: #343643;
    box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.20);
    z-index: 111;
  }
  .inner-wrapper {
    width: 90vw;
    margin-inline: auto;
  }
}
