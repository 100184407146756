.container {
  margin-inline: 20px;
  box-sizing: border-box;
  width: 90vw;
}

.pic {
  margin-inline: auto;
}

.greyContainer {
  border-radius: 16px;
  background: var(--grey, #343643);
  padding: 30px 20px;
  width: 89vw;
  box-sizing: border-box;
  overflow: hidden;
}

.gallery {
  overflow-x: auto;
}

.gallery-row {
  display: flex;
  transition: margin-left 0.5s; /* Add a transition for smooth scrolling */
}

.arrow {
  float: right;
  margin-left: auto;
  margin-right: 0px;
  margin-top: 20px;
  cursor: pointer;
}
