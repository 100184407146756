.container {
  margin-inline: auto;
  box-sizing: border-box;
  width: 1164px;
}

.picGal {
 // margin-inline: auto;

}

.greyContainer {
  border-radius: 16px;
  background: var(--grey, #343643);
  padding: 30px 60px;
  box-sizing: border-box;
  position: relative;
}

.gallery {
  overflow-x: auto;
}

.gallery-row {
  display: flex;
  transition: margin-left 0.5s; /* Add a transition for smooth scrolling */
  position: absolute;
  bottom: 30px;
}

.arrow {
  float: right;
  margin-left: auto;
  margin-right: -30px;
  cursor: pointer;
}
