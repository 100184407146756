.calendar-container {
  box-sizing: border-box;
  width: 332px;
  height: 475px;
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.2);
  border-radius: 30px;
  position: absolute;
  top: 30px;
  left: 0;
  padding: 22px;
  z-index: 10;
}
.right-chevron {
  transform: rotate(180deg)
}
.month-slider-container {
  z-index: 99;
}
.month-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  text-align: center;

  /* dark */

  color: #252730;
}
.days-of-week {
  display: grid;
}
.day-of-week {
  width: 100%;
  grid-column: 1;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  /* sea */

  color: #76CCCC;
}
.calendar-grid {
  /* Styles for the calendar grid container */
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  z-index: 999;
}
.calendar-grid > div {
  /* Styles for each day in the grid */
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  /* identical to box height, or 150% */
  cursor: pointer;
  letter-spacing: 0.5px;

  /* dark */

  color: #252730;


}

.today {
  justify-content: center;
  align-items: center;
  display: flex;
  /* Style for the current day */
  color: #76CCCC !important;
  font-weight: bold !important;
  width: 40px;
  height: 40px;

  /* sea */

  border-radius: 80px;
}

.other-month {
  color: #252730;
  /* Style for days in the previous or next month */
}
@media screen and (max-width: 767px){
  .calendar-container {
    box-sizing: border-box;
    width: 300px;
    height: 420px;
    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    box-shadow: 0px 4px 20px rgba(29, 53, 87, 0.2);
    border-radius: 30px;
    position: absolute;
    top: 30px;
    left: 0;
    padding: 22px;
  }
  .right-chevron {
    transform: rotate(180deg)
  }
  .month-slider-container {
    z-index: 99;
  }
  .month-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height, or 127% */

    text-align: center;

    /* dark */

    color: #252730;
  }
  .days-of-week {
    display: grid;
  }
  .day-of-week {
    width: 100%;
    grid-column: 1;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;

    /* sea */

    color: #76CCCC;
  }
  .calendar-grid {
    /* Styles for the calendar grid container */
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    z-index: 999;
  }
  .calendar-grid > div {
    /* Styles for each day in the grid */
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    /* identical to box height, or 150% */
    cursor: pointer;
    letter-spacing: 0.5px;

    /* dark */

    color: #252730;


  }

  .today {
    justify-content: center;
    align-items: center;
    display: flex;
    /* Style for the current day */
    color: #FFFFFF !important;
    width: 40px;
    height: 40px;

    /* sea */

    background: #76CCCC;
    border-radius: 80px;
  }

  .other-month {
    color: #252730;
    /* Style for days in the previous or next month */
  }
}

/* Calendar.css */

/* Styles for the calendar container */
.calendar-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px;
}

/* Styles for the calendar header */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Styles for the calendar grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

/* Styles for the day labels */
.day-label {
  text-align: center;
  font-weight: bold;
  padding: 6px;
  background-color: #f0f0f0;
}

/* Styles for the day cells */
.day-cell {
  text-align: center;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  color: #9F2951;
}

/* Styles for the empty cells (for days outside the current month) */
.day-cell.empty {
  background-color: #f0f0f0;
  cursor: default;
}

/* Styles for the previous and next month's days */
.day-cell.empty::before {
  content: attr(data-day);
  display: block;
  font-size: 12px;
  opacity: 0.5;
}

.MyDatePicker {
  float: left;
  position: relative;
}
.MyDatePicker * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently */
}

.mdp-input{
  float: left;
  width: 150px;
  height: 35px;
  overflow: hidden;
  border-radius: 20px;
}
.mdp-input input:focus{
  outline: none;
}
.mdp-input input{
  width: 130%;
  background: #f5f5f5;
  border: none;
  padding-right: 10px;
  height: 35px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
  cursor: pointer;
}

.mdp-container {
  float: left;
  position: absolute;
  left:0;
  top: 40px;
  width: 332px;
  min-height: 422px;
  background: #fff;
  box-shadow: 10px 10px 40px rgba(0,0,0,0.2);
  border-radius: 20px;
  overflow: hidden;
  padding: 50px 30px 0 30px;
  box-sizing: border-box;
  z-index: 10;
}
.mdpc-head{
  float: left;
  width: 100%;
  height: 53px;
}
.mdpc-body{
  float: left;
  width: 100%;
  margin-top: 20px;
}
.mdpc-footer{
  float: left;
  width: 100%;
  //margin-top: 20px;
  height: 60px;
}

/**
 * Controls
 */

.mdpch-button{
  float: left;
  width: 45px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.mdpchb-inner:hover > span{
  border-color: #fff!important;
}
.mdpchb-inner:hover{
  cursor: pointer;
  background: #eee;
}
.mdpchb-inner {
  float: left;
  height: 35px;
  width: 35px;
  background: #f4f4f4;
  border-radius: 100%;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top:50%;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
}

.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after,
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows{
  display: block;
  float: left;
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  position: absolute;
}
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows{
  transform: rotate(45deg);
  left: 50%;
  top:50%;
  margin-left: -2px;
  margin-top: -4px;
}
.mdpchbi-right-arrows,
.mdpchbi-right-arrow {
  transform: rotate(225deg);
  margin-left: -4px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after{
  content: '';
}

.mdpchbi-left-arrows{
  margin-left: -5px;
}
.mdpchbi-right-arrows{
  margin-left: -2px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after{
  left: 3px;
  top: -5px
}


.mdpch-container{
  float: left;
  width: 120px;
  height: 100%;
}
.mdpchc-year{
  float: left;
  width: 100%;
  height: 30px;
  font-size: 27px;
  color: #666;
  font-weight: 200;
  text-align: center;
}
.mdpchc-month{
  float: left;
  width: 100%;
  height: 15px;
  font-size: 13px;
  color: #666;
  font-weight: 200;
  text-align: center;
}

/**
 *  Calendar
 */
.cc-month,
.cc-head,
.cch-name,-n
.cc-body,
.cdc-day span,
.cdc-day,
.c-day-container,
.c-container{
  position: relative;
  display: block;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.c-container{
  width: 100%;
  height: 100%;
}

.cc-month{
  height: 30px;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.cc-head{
  height: 30px;
  width: 100%;
  margin-top: 10px;
}
.cch-name{
  width: 14.285%;
  height: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 150% */


  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  /* sea */

  color: #76CCCC;
}
.cc-body{
  height: 270px;
  width: 100%;
}
.c-day-container{
  width: 14.285%;
  height: 14.6666%;
}
.cdc-day{
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
}
.cdc-day span{
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
}

.cdc-day span:hover{
  cursor: pointer;
  background: #eee;
}
.cdc-day span{
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  font-weight: 400;
  border-radius: 100%;
  line-height: 30px;
}
.c-day-container.disabled{
  pointer-events: none;
  opacity: 0;
}

.c-day-container.disabled .cdc-day span{
  color: #fff;
  display: none;
}
.c-day-container.disabled .cdc-day span{
  background: #fff!important;
}
.c-day-container.highlight .cdc-day span{
  background: #76CCCC;
}
.c-day-container.highlight-green .cdc-day span{
  background: #d4e2cb;
}
.highlight {
  background: #76CCCC;
  border-radius: 50%;
}

@media screen and (max-width: 500px) {
  .mdp-container {
    float: left;
    position: absolute;
    left:0;
    top: 40px;
    width: 332px;
    min-height: 422px;
    background: #fff;
    box-shadow: 10px 10px 40px rgba(0,0,0,0.2);
    border-radius: 20px;
    overflow: hidden;
    padding: 30px 30px 0 30px;
    box-sizing: border-box;
    z-index: 10;
  }
}
