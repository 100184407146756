.menu {
  border-top: 1px solid var(--grey-medium, #525460);
  background: var(--grey, #343643);
  display: flex;
  width: 100vw;
  padding: 5px 15px 20px 15px;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  height: 70px;
  position: fixed;
  bottom: 0;
  z-index: 2000;
}

.menuText {
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sea {
  color: #76CCCC;
}

.disabledItem {
  opacity: 0.3;
}

.unlockButton {
  background: #76CCCC;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  width: 66px;
  height: 66px;
  border-radius: 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
