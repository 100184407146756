.card {
  border-radius: 8px;
  background: transparent;
  padding: 15px 15px 20px 15px;
  width: 330px;
  min-height: 650px;
  box-sizing: border-box;
  position: relative;
}

.inCardImage {
  width: 300px;
  height: 350px;
  border-radius: 8px;
  object-fit: cover;
  background-size: cover;
  position: relative;
}

.inCardImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust the opacity (last value) to control darkness */
}

.favorite {
  position: absolute;
  right: 27px;
  top: 27px;
  cursor: pointer;
  z-index: 2;
}

.leftChevron {
  transform: rotate(180deg);
  left: 0;
}


.buttonRight {
  position: absolute;
  right: 10px;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  cursor: pointer;
}

.buttonLeft {
  position: absolute;
  border-radius: 15px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background: white;
  width: 30px;
  height: 30px;
  z-index: 999;
  cursor: pointer;
}

.dots {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: white;
}

.inactiveDot {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: #525460;
}

.spotsName {
  color: #fff;
  /* spot names */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 500px) {
  .card {
    border-radius: 8px;
    background: var(--white, #FFF);
    padding: 15px 15px 20px 15px;
    width: 330px;
    min-height: 600px;
    box-sizing: border-box;
    position: relative;
  }
}
